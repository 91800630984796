(function () {
  'use strict';

  angular
  .module('neo.home.refereeNewInvoice.newline')
  .config(config);

  function config($stateProvider) {
    $stateProvider
    .state('home.new-line', {
      url: '/referee-new-invoice/new-line/{licenseId}/inv',
     
          templateUrl: 'home/referee-new-invoice/new-line/new-line.tpl.html',
          controller: 'RefereeNewLine',
          controllerAs: 'vm'
        ,
      resolve:{
       
        refereeLicense: function (RefereeLicence,  $stateParams){
          return RefereeLicence.get({licenceId :  $stateParams.licenseId}).$promise;
    },
    refId:function ($stateParams){
        return $stateParams.licenseId;
    },
    Receptores: function(receptores,$stateParams){
        return receptores.query();
    }
      }
    });
  }
}());


